<template>
    <transition name="fade">
        <div class="rightMenu" v-if="flag">
            <!--产品中心-->
            <div class="products" v-if="types==1">
                <div class="product-main">
                    <!--循环商品，根据二级菜单类型不同，三级会展示不同样式。-->
                    <div class="shop-list" v-for="(item,index) in datas" v-show="index==cur">
                        <!--<ul class="types" v-if="item.type==1">-->
                        <ul class="types">
                            <li v-for="(it,ind) in item.arr">
                                <div class="shop-item">
                                    <div class="shop-item-pic">
                                        <img :src="'/images/website/' + it.image_url" alt="" />
                                    </div>
                                    <div class="shop-item-title">{{it.name}}</div>
                                    <div class="shop-item-brief">{{it.introduction}}</div>
                                    <div class="shop-item-more" @click="goto(it)">
                                        <span>了解更多</span>>>>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <!--代理产品容器-->
                        <!--<ul class="types2" v-else-if="item.type==2">-->
                            <!--<li class="types2-tabs">-->
                                <!--<div>-->
                                    <!--<p v-for="(it,ind) in item.arr" @mouseenter="changeProxyCurFn(ind)" :class="ind==proxy_cur?'curr':''">{{it.title}}</p>-->
                                <!--</div>-->
                            <!--</li>-->
                            <!--<li class="types2-list-li" v-for="(it,ind) in item.arr[proxy_cur].arr2">-->
                                <!--<div class="shop-item">-->
                                    <!--<div class="shop-item-pic">-->
                                        <!--<img :src="/imgs/+it.img" alt="" />-->
                                    <!--</div>-->
                                    <!--<div class="shop-item-title">{{it.title}}</div>-->
                                    <!--<div class="shop-item-brief">{{it.brief}}</div>-->
                                    <!--<div class="shop-item-more">-->
                                        <!--<span>了解更多</span>>>-->
                                    <!--</div>-->
                                <!--</div>-->
                            <!--</li>-->
                        <!--</ul>-->
                    </div>
                </div>
            </div>
            <!--解决方案-->
            <div class="solution" v-else-if="types==2">
                <div class="solution-main">
                    <!--循环方案,根据二级菜单不同，展示不同方案-->
                    <div class="plan-list" v-for="(item,index) in datas" v-show="index==cur">
                        <ul>
                            <li v-for="(it,ind) in item.arr">
                                <div class="plan-item">
                                    <div class="plan-item-pic">
                                        <img :src="'/images/website/' + it.image_url" alt="">
                                    </div>
                                    <div class="plan-item-title">{{it.name}}</div>
                                    <div class="plan-item-brief">{{it.introduction}}</div>
                                    <div class="plan-item-more" @click="goto(it)">
                                        <span>了解更多</span>>>>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "rightMenu",
        props:{
            cur:{       //当前选中的类目
                required:true,
                type:Number,
            },
            datas:{     //所有列表数据
                required: true,
                type:Array,
            },
            types:{     //区分产品或方案页面
                required:true,
                type:Number,
            }
        },
        data(){
            return{
                // proxy_cur:0,  // 代理产品默认高亮第一类
                flag:true,  // 重新生成dom，有动画效果
            }
        },
        methods:{
            //悬停改变代理cur
            // changeProxyCurFn(index){
            //     this.proxy_cur = index;
            // },
            //点击详情
            goto(it){
                this.$router.push({
                    name:'detail',
                    params:{aid:this.types==1?3:4},
                    query:{
                        pid:it.reference,
                        id:it.id,
                    },
                });
            },
        },
        watch:{
            cur(){
                this.flag = false;
                setTimeout(()=>{
                    this.flag = true;
                });
            },
        },
    }
</script>

<style scoped>
    .rightMenu {
        float: right;
        width: 760px;
        min-height: 630px;
        /*background-color: #ffffff;*/
    }
    /*产品中心样式*/
    .rightMenu .products{}
    .rightMenu .products .product-main{}
    .rightMenu .products .product-main .shop-list{}
    .rightMenu .products .product-main .shop-list ul.types{
        overflow: hidden;
    }
    .rightMenu .products .product-main .shop-list ul.types li{
        width: 370px;
        float: left;
        background-color: #ffffff;
        margin-right: 20px;
        padding: 0 23px 23px;
        box-sizing: border-box;
        margin-bottom: 20px;
    }
    .rightMenu .products .product-main .shop-list ul.types li:nth-child(2n){
        margin-right: 0;
    }
    .shop-item{}
    .shop-item .shop-item-pic{
        text-align: center;
    }
    .shop-item .shop-item-pic img{
        /*width: 220px;*/
        height: 260px;
    }
    .shop-item .shop-item-title{
        font-size: 20px;
        font-weight: 600;
        color: #333333;
        line-height: 52px;
    }
    .shop-item .shop-item-brief{
        font-size: 16px;
        line-height: 24px;
        color: #999;
        margin-bottom: 40px;
        height: 72px;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
    }
    .shop-item .shop-item-more{
        font-size: 16px;
        color: #333;
        font-weight: 600;
        cursor: pointer;
    }
    .shop-item .shop-item-more span{
        margin-right: 20px;
    }

    /*代理产品样式*/
    .rightMenu .products .product-main .shop-list ul.types2{
        overflow: hidden;
    }
    .rightMenu .products .product-main .shop-list ul.types2 li.types2-tabs{
        margin-bottom: 20px;
    }
    .rightMenu .products .product-main .shop-list ul.types2 li.types2-tabs div{
        overflow: hidden;
    }
    .rightMenu .products .product-main .shop-list ul.types2 li.types2-tabs div p{
        float: left;
        margin-right: 20px;
        border: 1px solid #666;
        color: #666;
        padding: 2px 10px;
        box-sizing: border-box;
        border-radius: 10px;
        cursor: pointer;
    }
    .rightMenu .products .product-main .shop-list ul.types2 li.types2-tabs div p:hover{
        border-color: #333;
        color: #333;
    }
    .rightMenu .products .product-main .shop-list ul.types2 li.types2-tabs div p.curr{
        border-color: #333;
        color: #333;
    }
    .rightMenu .products .product-main .shop-list ul.types2 li.types2-list-li{
        width: 370px;
        float: left;
        background-color: #ffffff;
        padding: 0 23px 23px;
        box-sizing: border-box;
        margin-bottom: 20px;
    }
    .rightMenu .products .product-main .shop-list ul.types2 li.types2-list-li:nth-child(2n){
        margin-right: 20px;
    }

    /*解决方案样式*/
    .rightMenu .solution{}
    .rightMenu .solution .solution-main{}
    .rightMenu .solution .solution-main .plan-list{}
    .rightMenu .solution .solution-main .plan-list ul{}
    .rightMenu .solution .solution-main .plan-list ul li{
        background-color: #ffffff;
        padding: 0 23px 23px;
        box-sizing: border-box;
        margin-bottom: 20px;
    }
    .plan-item{}
    .plan-item .plan-item-pic{
        text-align: center;
    }
    .plan-item .plan-item-pic img{
        width: 100%;
    }
    .plan-item .plan-item-title{
        font-size: 20px;
        font-weight: 600;
        color: #333333;
        line-height: 52px;
    }
    .plan-item .plan-item-brief{
        font-size: 16px;
        line-height: 24px;
        color: #999;
        margin-bottom: 40px;
    }
    .plan-item .plan-item-more{
        font-size: 16px;
        color: #333;
        font-weight: 600;
        cursor: pointer;
    }
    .plan-item .plan-item-more span{
        margin-right: 20px;
    }




    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>