<template>
    <div class="leftMenu">
        <div class="leftMenu-title"></div>
        <div class="leftMenu-list">
            <ul>
                <li v-for="(item,index) in category">
                    <span :class="cur==index?'curr':''" @mouseenter="changeCur(index)">{{item.name}}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "leftMenu",
        props:{
            cur:{
                type:Number,
                required:true,
            },
            category:{
                type:Array,
                required:true,
            },
        },
        data(){
            return{
                //
            }
        },
        methods:{
            //悬停改变
            changeCur(index){
                this.$emit('changeCur',index);
            },
        },
    }
</script>

<style scoped>
    .leftMenu{
        float: left;
        width: 220px;
        /*height: 630px;*/
        background-color: #ffffff;
    }
    .leftMenu-title{}
    .leftMenu-list{}
    .leftMenu-list ul{
        padding: 15px 18px;
        box-sizing: border-box;
    }
    .leftMenu-list ul li{
        padding: 11px 0;
        box-sizing: border-box;
        line-height: 25px;
    }
    .leftMenu-list ul li span{
        cursor: pointer;
        display: block;
        font-size: 16px;
        position: relative;
        color: #666;
    }
    .leftMenu-list ul li span.curr,.leftMenu-list ul li span:hover{
        color: #333;
        font-weight: 600;
    }

    .leftMenu-list .curr:after, .leftMenu-list span:hover:after {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        width: 25px;
        height: 25px;
        background-image: url("../../assets/imgs/right2.png");
        background-size: 100% auto;
    }

</style>