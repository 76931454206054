<template>
    <div class="footer">
        <div class="footer-wrap">
            <div class="footer-info">Copyright © 2014 哈工精密技术（深圳）有限公司 版权所有</div>
            <!--<div class="footer-info">Copyright © 2008 深圳国检计量测试技术有限公司 版权所有</div>-->
            <div class="footer-info2">
                <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2021178225号</a>
                <!--<a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2021168229号</a>-->
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PublicFooter"
    }
</script>

<style scoped>
    .footer{
        background-color: #272727;
        border-top: 1px solid #d4d4d4;
        /*position: fixed;*/
        width: 100%;
        /*bottom: 0;*/
    }
    .footer-wrap{
        margin: 0 auto;
        width: 1000px;
        overflow: hidden;
        line-height: 80px;
        font-size: 14px;
    }
    .footer-info{
        float: left;
        margin-right: 40px;
        color: #999999;
    }
    .footer-info2{
        float: left;
    }
    .footer-info2 a{
        color: #999999;
    }
</style>