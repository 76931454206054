<template>
    <div class="solution">
        <div class="bg-pic" v-if="obj.solutionbackground" :style="{backgroundImage:'url(/images/website/' + obj.solutionbackground + ')'}"></div>
        <div class="content">
            <div class="content-main">
                <left-menu :cur="cur" :category="category" @changeCur="changeCur" />
                <right-menu :cur="cur" :datas="datas" :types="2" />
            </div>
        </div>
        <public-footer />
    </div>
</template>

<script>
    import publicFooter from '@/components/common/PublicFooter'

    import leftMenu from '@/components/common/leftMenu'
    import rightMenu from '@/components/common/rightMenu'
    export default {
        name: "Solution",
        components:{
            publicFooter,
            leftMenu,
            rightMenu,
        },
        data(){
            return{
                obj:{}, //公司信息
                cur:0,  // 默认选中第一个
                category:[],  //左侧分类
                datas:[],     //右侧数据
            }
        },
        created(){
            this.getNavs();
            this.getCompanyInfo();
        },
        methods:{
            //获取公司信息
            async getCompanyInfo(){
                if(this.$store.getters.getCompanyInfo){
                    this.obj = this.$store.getters.getCompanyInfo;
                }else{
                    try {
                        const {data} = await this.$axios('/api/companyprofile');
                        if(data.code){
                            this.obj = data.data[0];
                            this.$store.dispatch('setCompanyInfo',data.data[0]);
                        }
                    }catch (e) {
                        //
                    }
                }
            },
            //获取头部
            async getNavs(){
                if(this.$store.getters.getNavsData){
                    const navs = JSON.parse(JSON.stringify(this.$store.getters.getNavsData));
                    for(let i=0;i<navs.length;i++){
                        if(navs[i].id == 4){
                            if(navs[i].category){
                                this.category = navs[i].category;
                                if(this.category[0].arr){
                                    this.datas = this.category;
                                }else{
                                    this.datas = [];
                                }
                            }
                            break;
                        }
                    }
                }else{
                    try {
                        const {data} = await this.$axios({
                            url:'/api/firsttitle'
                        });
                        if(data.code){
                            this.$store.dispatch('setNavsData',data.data);

                            for(let i=0;i<data.data.length;i++){
                                if(data.data[i].id == 4){
                                    if(data.data[i].category){
                                        this.category = data.data[i].category;
                                        if(this.category[0].arr){
                                            this.datas = this.category;
                                        }else{
                                            this.datas = [];
                                        }
                                    }
                                }
                            }
                        }
                    }catch (e) {
                        console.log(e);
                    }
                }
            },
            //改变cur
            changeCur(index){
                this.cur = index;
            },
        },
    }
</script>

<style scoped>
    .solution{
        background-color: #F8F8F8;
    }
    .bg-pic{
        width: 100%;
        height: 400px;
        background-size: cover;
        /*background-image: url("/imgs/product_banner.jpg");*/
    }
    .content{
        width: 100%;
        padding: 30px 0;
        box-sizing: border-box;
        background-color: #f5f5f5;
    }
    .content-main{
        width: 1000px;
        margin: 0 auto;
        overflow: hidden;
    }
</style>